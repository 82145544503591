import gql from 'graphql-tag';

const GET_ALL_PLANS = gql`
  query getAllPlans {
    plans @rest(type: "Plans", path: "plans/") {
      id
      name
      show_vat
      initial_fees
      recurring_fees
      booking_fee
    }
  }
`;

const GET_CALCULATOR_DATA = gql`
  query getCalculatorData {
    plans @rest(type: "Plans", path: "plans/") {
      id
      name
    }
    vehicleTypes @rest(type: "VehicleTypes", path: "vehicle-types/") {
      id
      name
    }
  }
`; 

const GET_PLAN_VEHICLE_PRICES = gql`
  query getPlanVehiclePrices(
    $planId: Number,
  ) {
    planVehiclePrices (
      planId: $planId
    ) @rest(type: "PlanVehiclePrices", path: "plans/{args.planId}/vehicle_prices/") {
      id
      name
      price_for_time
      price_for_km
    }
  }
`;

export {
  GET_ALL_PLANS,
  GET_CALCULATOR_DATA,
  GET_PLAN_VEHICLE_PRICES,
}