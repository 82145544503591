import gql from 'graphql-tag';

const GET_VEHICLE_LOCATIONS = gql`
  query getVehicleLocations {
    locations @rest(type: "Locations", path: "locations/") {
      id
      name
      coordinates
      description
      vehicle_types
    }
  }
`;

const GET_PRICE_FOR_RESERVATION = gql`
  query getPriceForReservation(
    $start: String,
    $end: String,
    $vehicleType: Number,
    $plan: Number,
  ) {
    priceForReservation(
      start: $start,
      end: $end,
      vehicle_type: $vehicleType,
      plan: $plan
    )
      @rest(type: "PriceForReservation", path: "get-price-for-reservation/?{args}") {
        time_price
        km_prices
        booking_fee
      }

    vehicleTimePrices(
      plan: $plan
    )
      @rest(type: "VehicleTimePrices", path: "plans/{args.plan}/vehicle_prices/") {
        id
        name
        price_for_time
      }
  }
`;

export {
  GET_VEHICLE_LOCATIONS,
  GET_PRICE_FOR_RESERVATION,
}