const videoAndText = `
  applyVerticalStyling
  additionalDescription
  ctaButtonLabel
  ctaButtonUrl {
    ... on Page {
      link
    }
  }
  excerpt
  fieldGroupName
  headline
  textColumns {
    content
    headline
    headlineIcon {
      altText
      title
      sourceUrl(size: LARGE)
      mediaDetails {
        width
        height
      }
    }
  }
  video {
    poster {
      sourceUrl(size: LARGE)
    }
    id
  }
`;

const compareMembership = `
  fieldGroupName
  headline
  button 
  compareTable { 
    tableContent {
      rows {
        fieldGroupName
        value
      }
    }
    tableHeader {
      column {
        value
      }
    }
  }
`

const contactFormular = `
fieldGroupName
headline
description
form {
            emailLabel
            fieldGroupName
            formEndpointEmail
            messageLabel
            nameLabel
            phoneLabel
            successMessage
            requiredText
            errorMessage
          }
          headline
          imagesGroup {
            imageTop {
              altText
              sourceUrl(size: LARGE)
            }
            imageLeft {
              altText
              sourceUrl(size: LARGE)
            }
            imageRight {
              altText
              sourceUrl(size: LARGE) 
            }
            imageBottom {
              altText
              sourceUrl(size: LARGE)
            }
          }
`

const productMatrix = `
  fieldGroupName
  products {
    title
    subtitle
    logo {
      altText
      sourceUrl(size: LARGE)
      title
      mediaDetails {
        width
        height
      }
    }
    priceTable {
      bigCar {
        headline
        price
      }
      middleCar {
        headline
        price
      }
      smallCar {
        headline
        price
      }
    } 
    price
    priceSuffix
    subscriptionInfo
    reservationFee
    readMoreLabel
    readMoreLink {
      ... on Page {
        link
      }
    }
  }
`;

const carSlider = `
  description
  fieldGroupName
  headline
  carSlides {
    carType {
      ... on Vehicle {
        title
        featuredImage {
          altText
          sourceUrl(size: LARGE)
          title
          mediaDetails {
            width
            height
          }
        }
      }
    }
  }
`;

const carMap = `
  ctaLabel
  description
  ctaLink {
    ... on Page {
      id
      link
    }
  }
  headline
  mapSearchPlaceholderText
  mapFullWidth
  showSearchInput
  fieldGroupName
`;

const promotion = `
  description
  fieldGroupName
  headline
  image {
    altText
    sourceUrl(size: LARGE)
    title
    mediaDetails {
      width
      height
    }
  }
  tiles {
    description
    title
    icon {
      altText
      sourceUrl(size: LARGE)
      title
      mediaDetails {
        width
        height
      }
    }
  }
`;

const subscription = `
  ctaLabel
  ctaUrl
  description
  dividerText
  fieldGroupName
  headline
  readMoreLabel
  readMoreLink {
    ... on Page {
      link
    }
  }
  leftImage {
    altText
    sourceUrl(size: LARGE)
    title
    mediaDetails {
      width
      height
    }
  }
  rightImage {
    altText
    sourceUrl(size: LARGE)
    title
    mediaDetails {
      width
      height
    }
  }
`;

const faq = `
  description
  fieldGroupName
  headline
  questions {
    content
    title
    faqId
  }
`;

const priceTable = `
  description
  fieldGroupName
  rows {
    label
  }
  toggleButtonLabel
  plans {
    plan {
      ... on Plan {
        planDetails {
          planId
          cheapestLongHaulPrice
          cheapestShortHaulPrice
          freeNights
        }
      }
    }
  }
`;

const priceCalculator = `
  ctaButtonLabel
  description
  fieldGroupName
  headline
  plans {
    plan {
      ... on Plan {
        planDetails {
          planId
          enableFreeNightsCheck
        }
      }
    }
  }
  resultTableLabels {
    allPricesLabel
    bookingLabel
    carDefaultLabel
    dayTimeLabel
    first100Label
    nightTimeLabel
    over100Label
    totalPriceLabel
    tableHeaderLabels {
      priceLabel
      subtotalLabel
      typeLabel
    }
  }
  userInputLabels {
    datetimePickerPlaceholder
    endDateLabel
    planLabel
    selectDefaultPlaceholder
    startDateLabel
    totalDistanceLabel
    vehicleTypeLabel
  }
`;

const smallTextBlock = `
  description
  fieldGroupName
  headline
  tagLine
`;

const vehicleComparison = `
  fieldGroupName
  description
  headline
  vehicles {
    fieldGroupName
    vehicle {
      text
      type
      image {
        altText
        sourceUrl
      }
      price { 
        fieldGroupName 
         priceplan {
            fieldGroupName
            id
            name
            text
          }
      }
    }
  }
  plans {
    name
    id
  }
`;

const twoColumnsText = `
  headline
  headlineH1Tag
  fieldGroupName
  leftColumn {
    description
  }
  rightColumn {
    description
  }
`;

const collaboratorSlider = `
  description
  fieldGroupName
  headline
  collaborators {
    logo {
      altText
      title
      sourceUrl(size: LARGE)
      mediaDetails {
        height
        width
      }
    }
  }
`;

const textTiles = `
  fieldGroupName
  tiles {
    content
    title
  }
`;

const googleMap = `
  description
    fieldGroupName
    headline
    location {
      latitude
      longitude
      streetAddress
    }
    mapInformation
`;

const employeeList = `
  description
  fieldGroupName
  headline
  employees {
    jobTitle
    name
    email
    profileImage {
      altText
      sourceUrl(size: LARGE)
      title
      mediaDetails {
        height
        width
      }
    }
  }
`;

const testimonials = `
  fieldGroupName
  headline
  tiles {
    feedback
    fieldGroupName
    memberDetails
    name
    profileImage {
      altText
      sourceUrl(size: THUMBNAIL)
      title
      mediaDetails {
        width
        height
      }
    }
  }
`;

export {
  videoAndText,
  productMatrix,
  compareMembership,
  carSlider,
  carMap,
  promotion,
  subscription,
  faq,
  priceTable,
  priceCalculator,
  smallTextBlock,
  vehicleComparison,
  twoColumnsText,
  collaboratorSlider,
  textTiles,
  googleMap,
  employeeList,
  testimonials,
  contactFormular
}
