import React, { useEffect, lazy, Suspense } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Router, Redirect } from '@reach/router';
import AOS from 'aos';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import 'aos/dist/aos.css';
import './../../wp-includes/css/dist/block-library/style.min.css';
import {
  GET_ALL_ROUTES_AND_MENUS,
  GET_SETTINGS,
} from './gql';
import { getLinkPathname } from './utils';
import Throbber from './components/Throbber';

const PopUp = lazy(() => import('./components/PopUp'));
const MessageContainer = lazy(() => import('./components/MessageContainer'));
const PageTemplate = lazy(() => import('./templates/Page'));
const FrontpageTemplate = lazy(() => import('./templates/Frontpage'));
const BlogpageTemplate = lazy(() => import('./templates/Blogpage'));
const BlogpostTemplate = lazy(() => import('./templates/BlogPost'));
const PageBlocksOnlyTemplate = lazy(() => import('./templates/PageBlocksOnly'));
const NotFound = lazy(() => import('./templates/NotFound'));

const App = () => {
  // Google Tag Manager Id
  const tagManagerArgs = {
    gtmId: 'GTM-PQRT4C'
  }

  const { data: settings } = useQuery(GET_SETTINGS);
  const { loading, error, data, client } = useQuery(GET_ALL_ROUTES_AND_MENUS);


  const {
    isMembersSection,
    popupDismissed,
    currentLanguage,
  } = settings;
  useEffect(() => {
    const hj = { id: 1941543, sv: 6 };
    TagManager.initialize(tagManagerArgs)
    AOS.init(
      {
        once: true,
        duration: 200
      }
    );
    hotjar.initialize(hj.id, hj.sv);
  }, []);

  const setCurrentLanguage = () => {
    const { languages, defaultLanguage } = data;
    const windowPathname = window.location.pathname;
    const allPathnames = windowPathname.split('/');

    const languageDetails = languages.filter(language => {
      const langCode = language.code.toLowerCase();
      const languageIndex = allPathnames.indexOf(langCode);
      return languageIndex > -1;
    });
    client.writeData({
      data: {
        currentLanguage: languageDetails.length > 0
          ? languageDetails[0].code
          : defaultLanguage.code
      }
    });
  }

  const renderPageRoutes = (page, menus, languages) => {
    const {
      isMembersSection
    } = settings;

    const {
      id,
      link,
      childPages,
      pageTemplate,
      isFrontPage,
    } = page.node;

    switch(pageTemplate.pageTemplate) {

    case 'blogPage':
    return [
      <BlogpageTemplate
          key={id}
          id={id}
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
          path={getLinkPathname(link)}
      />,
      childPages &&
      childPages.edges.map(childPage =>
          renderPageRoutes(childPage, menus, languages)
      )
    ]
    case 'frontpage':
      return [
        <FrontpageTemplate
          key={id}
          id={id}
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
          path={isFrontPage ? '/' : getLinkPathname(link)}
        />,
        childPages &&
          childPages.edges.map(childPage =>
            renderPageRoutes(childPage, menus, languages)
          )
      ];
    case 'pageBlocksOnly':
      return [
        <PageBlocksOnlyTemplate
          key={id}
          id={id}
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
          path={getLinkPathname(link)}
        />,
        childPages &&
          childPages.edges.map(childPage =>
            renderPageRoutes(childPage, menus, languages)
          )
      ];
    default:
      return [
        <PageTemplate
          key={id}
          id={id}
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
          path={getLinkPathname(link)}
        />,
        childPages &&
          childPages.edges.map(childPage =>
            renderPageRoutes(childPage, menus, languages)
          )
      ];
    }
  }

  if (loading) return <Throbber loadingText="" />;
  if (error) return <MessageContainer description={error.message} />;

  const {
    pages,
    menus,
    popUpDa,
    popUpEn,
    languages,
  } = data;


  setCurrentLanguage();
  return (
    <Suspense fallback={<Throbber loadingText="" />}>
      <Router>
        <Redirect from="da" to="/" />

        <BlogpostTemplate path="/blog/:id"
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
        />
        <BlogpostTemplate path="/en/blog_en/:id"
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
        />
        {pages.edges.map(page =>
            renderPageRoutes(page, menus, languages)
        )}
        <NotFound
          default
          menus={menus}
          client={client}
          isMembersSection={isMembersSection}
          currentLanguage={currentLanguage}
          languages={languages}
        />
      </Router>
      {!popupDismissed ? <PopUp
        client={client}
        data={{content: { popUpDa, popUpEn }}}
        currentLanguage={currentLanguage}
        isMembersSection={isMembersSection}
      />
        : null
      }
    </Suspense>
  );
}

export default App;
