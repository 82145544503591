import React from 'react';
import { Link } from '@reach/router';
import cx from 'classnames';
import _ from 'lodash';

const getLinkPathname = url => {
  const pathname = new URL(url).pathname;
  return pathname;
}

const getLastLinkPathname = url => {
  const pathnames = url.split('/');
  return '/' + pathnames[pathnames.length - 2];
}

const getLinkElement = (node, className) => {
  const { onclick, url, target, label } = node;
  if (!url) {
    return null;
  }
  const urlObj = new URL(url);
  const linkHostname = urlObj.hostname;

  if (linkHostname === window.location.hostname || linkHostname === process.env.HOSTNAME) {
    return (
      <Link
        className={cx(className)}
        to={getLinkPathname(url)}
        onClick={() => onclick ? onclick() : null}
      >
        {label}
      </Link>
    );
  }

  return (
    <a
      className={cx(className)}
      href={url} rel="noopener noreferrer"
      target={target}
      onClick={() => onclick ? onclick() : null}
    >
      {label}
    </a>
  );
}

const formatDate = date => {
  const dateObject = new Date(date);
  const formatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const formattedDate = new Date(
    Date.UTC(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate()
    )
  );
  return formattedDate.toLocaleDateString('da-DK', formatOptions);
}

const formatPrice = (price, currency, language, digits) => {
  const langCode = language || 'da-DK';
  const maxDigits = digits || 0; 
  return new Intl.NumberFormat(langCode, {
    minimumFractionDigits: maxDigits,
    maximumFractionDigits: maxDigits,
    style:'currency',
    currency
  }).format(price);
}

const openEmailClient = emailAddress => {
  const decodedEmailAddress = _.unescape(emailAddress);
  const a = document.createElement('a');
  a.href = 'mailto:' + decodedEmailAddress;
  a.click();
}

const sanitizeNewlineChar = string => {
  return _.replace(string, /(?:\r\n|\r|\n|\↵)/g, '<br />') // eslint-disable-line no-useless-escape
}

export {
  getLinkPathname,
  getLinkElement,
  formatDate,
  getLastLinkPathname,
  formatPrice,
  openEmailClient,
  sanitizeNewlineChar,
};