import React from 'react';
import "regenerator-runtime"
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { RestLink } from 'apollo-link-rest';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

import '../scss/style.scss';
import App from './App';
import { defaults, resolvers } from './resolvers';

// custom error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // do something with graphql error
    console.error({ graphQLErrors });
  }

  if (networkError) {
    // do something with network error
    console.error({ networkError });
  }
});

const restLink = new RestLink({
  uri: process.env.REST_API,
});

const httpLink = new HttpLink({
  uri: `//${process.env.HOSTNAME}/graphql/`
});

const cache = new InMemoryCache({ fragmentMatcher });

const stateLink = withClientState({
  cache,
  resolvers,
  defaults,
});

const link = ApolloLink.from([
  restLink,
  errorLink,
  stateLink,
  httpLink,
]);

const client = new ApolloClient({
  cache,
  link,
  resolvers,
});

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.querySelector('main')
);
