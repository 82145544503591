import gql from 'graphql-tag';

const GET_SETTINGS = gql`
  query getSettings {
    isMembersSection @client
    popupDismissed @client
    currentLanguage @client
  }
`;

export {
  GET_SETTINGS,
}
