import {
  GET_ALL_ROUTES_AND_MENUS,
  GET_FRONTPAGE_CONTENT,
  GET_PAGE_CONTENT,
  GET_BLOG_POSTS,
  GET_BLOG_POST
} from './pages';
import {
  GET_SETTINGS,
} from './settings';
import {
  GET_VEHICLE_LOCATIONS,
  GET_PRICE_FOR_RESERVATION,
} from './vehicles';
import {
  GET_ALL_PLANS,
  GET_CALCULATOR_DATA,
  GET_PLAN_VEHICLE_PRICES,
} from './plans';

export {
  GET_ALL_ROUTES_AND_MENUS,
  GET_SETTINGS,
  GET_FRONTPAGE_CONTENT,
  GET_VEHICLE_LOCATIONS,
  GET_PAGE_CONTENT,
  GET_ALL_PLANS,
  GET_CALCULATOR_DATA,
  GET_PRICE_FOR_RESERVATION,
  GET_PLAN_VEHICLE_PRICES,
  GET_BLOG_POSTS,
  GET_BLOG_POST
};
