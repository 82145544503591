import gql from 'graphql-tag';
import {
  videoAndText,
  compareMembership,
  productMatrix,
  carSlider,
  carMap,
  promotion,
  subscription,
  faq,
  priceTable,
  priceCalculator,
  smallTextBlock,
  vehicleComparison,
  twoColumnsText,
  collaboratorSlider,
  textTiles,
  googleMap,
  employeeList,
  testimonials,
  contactFormular,
} from './shared';

const GET_ALL_ROUTES_AND_MENUS = gql`
  query getAllRoutesAndMenus {
    defaultLanguage {
      code
      name
    }
    languages {
      code
      name
    }
    menus(
      first: 99999,
    ) {
      edges {
        node {
          distributionMenu {
            menuItemLabel
            memberSectionLabel
          }
          menuItems {
            edges {
              node {
                cssClasses
                menuItemId
                url
                target
                label
              }
            }
          }
          menuId
          id
          name
          primaryMenu {
            logo {
              altText
              title
              sourceUrl(size: LARGE)
              mediaDetails {
                width
                height
              }
            }
          }
          footer {
            accounting {
              sectionContent
              sectionLabel
            }
            emergency {
              ctaLabel
              ctaUrl
              sectionContent
              sectionLabel
            }
            office {
              sectionContent
              sectionLabel
            }
            phone {
              sectionContent
              sectionLabel
            }
            sites {
              menuLabel
            }
          }
        }
      }
    }
    pages(
      first: 99999,
      where: {language: ALL}
    ) {
      edges {
        node {
          link
          id
          pageId
          pageTemplate {
            pageTemplate
          }
          isFrontPage
          childPages(first: 99999) {
            edges {
              node {
                id
                link
                pageId
                pageTemplate {
                  pageTemplate
                }
              }
            }
          }
        }
      }
    }
    popUpDa {
      popUp {
        popupContent {
          chooseSections
          startDate
          endDate
          headline
          description
          backgroundImage {
            altText
            sourceUrl(size: LARGE)
            title
          }
          ctaLabel
          ctaLink
        }
      }
    }
    popUpEn {
      popUp {
        popupContent {
          chooseSections
          startDate
          endDate
          headline
          description
          backgroundImage {
            altText
            sourceUrl(size: LARGE)
            title
          }
          ctaLabel
          ctaLink
        }
      }
    }
  }
`;

const GET_FRONTPAGE_CONTENT = gql`
  query getFrontpageContent(
    $id: ID!
  ) {
    page(id: $id) {
      pageId
      seo {
        title
      }
      frontpageSettings {
        typewriter {
          description
          headlineBeforeText
        }

        galleries {
          topLeaf {
            altText
            sourceUrl(size: LARGE)
            title
          }
          leftLeaf {
            altText
            sourceUrl(size: LARGE)
            title
          }
          rightLeaf {
            altText
            sourceUrl(size: LARGE)
            title
          }
          bottomLeaf {
            altText
            sourceUrl(size: LARGE)
            title
          }
        }
      }
      pageBlocks {
        content {
          ... on Page_Pageblocks_Content_VideoAndText {
            ${videoAndText}
          }
          ... on Page_Pageblocks_Content_ProductMatrix {
            ${productMatrix}
          }
          ... on Page_Pageblocks_Content_CompareMemberships {
            ${compareMembership}
          }
          ... on Page_Pageblocks_Content_Testimonials {
            ${testimonials}
          }
          ... on Page_Pageblocks_Content_CarSlider {
            ${carSlider}
          }
          ... on Page_Pageblocks_Content_CarMap {
            ${carMap}
          }
          ... on Page_Pageblocks_Content_Promotion {
            ${promotion}
          }
          ... on Page_Pageblocks_Content_Subscription {
            ${subscription}
          }
          ... on Page_Pageblocks_Content_Faq {
            ${faq}
          }
          ... on Page_Pageblocks_Content_PriceTable {
            ${priceTable}
          }
          ... on Page_Pageblocks_Content_PriceCalculator {
            ${priceCalculator}
          }
          ... on Page_Pageblocks_Content_SmallTextBlock {
            ${smallTextBlock}
          }
          ... on Page_Pageblocks_Content_VehicleCompareOverview {
            ${vehicleComparison}
          }
          ... on Page_Pageblocks_Content_TwoColumnsText {
            ${twoColumnsText}
          }
          ... on Page_Pageblocks_Content_CollaboratorSlider {
            ${collaboratorSlider}
          }
          ... on Page_Pageblocks_Content_TextTiles {
            ${textTiles}
          }
          ... on Page_Pageblocks_Content_GoogleMap {
            ${googleMap}
          }
          ... on Page_Pageblocks_Content_EmployeeList {
            ${employeeList}
          }
        }
      }
    }
  }
`;

const GET_PAGE_CONTENT = gql`
  query getPageContent(
    $id: ID!
  ) {
    page(id: $id) {
      pageId
      title
      content
      seo {
        title
      }
      pageBlocks {
        content {
          ... on Page_Pageblocks_Content_ContactFormular {
            ${contactFormular}
          }
          ... on Page_Pageblocks_Content_VideoAndText {
            ${videoAndText}
          }
          ... on Page_Pageblocks_Content_ProductMatrix {
            ${productMatrix}
          }
          ... on Page_Pageblocks_Content_CompareMemberships {
            ${compareMembership}
          }
          ... on Page_Pageblocks_Content_Testimonials {
            ${testimonials}
          }
          ... on Page_Pageblocks_Content_CarSlider {
            ${carSlider}
          }
          ... on Page_Pageblocks_Content_CarMap {
            ${carMap}
          }
          ... on Page_Pageblocks_Content_Promotion {
            ${promotion}
          }
          ... on Page_Pageblocks_Content_Subscription {
            ${subscription}
          }
          ... on Page_Pageblocks_Content_Faq {
            ${faq}
          }
          ... on Page_Pageblocks_Content_PriceTable {
            ${priceTable}
          }
          ... on Page_Pageblocks_Content_PriceCalculator {
            ${priceCalculator}
          }
          ... on Page_Pageblocks_Content_SmallTextBlock {
            ${smallTextBlock}
          }
          ... on Page_Pageblocks_Content_VehicleCompareOverview {
            ${vehicleComparison}
          }
          ... on Page_Pageblocks_Content_TwoColumnsText {
            ${twoColumnsText}
          }
          ... on Page_Pageblocks_Content_CollaboratorSlider {
            ${collaboratorSlider}
          }
          ... on Page_Pageblocks_Content_TextTiles {
            ${textTiles}
          }
          ... on Page_Pageblocks_Content_GoogleMap {
            ${googleMap}
          }
          ... on Page_Pageblocks_Content_EmployeeList {
            ${employeeList}
          }
        }
      }
    }
  }
`;


const GET_BLOG_POSTS = gql`
  query getBlogPosts(
    $language: LanguageCodeFilterEnum,
    $id: ID!
  ) {
    page(id: $id) {
      pageId
      title
      content
      seo {
        title
      }
      pageBlocks {
        content {
          ... on Page_Pageblocks_Content_BlogHeroModule {
            blogPostsOverviewLabel
            heroDescription
            headline
            readMoreLabel
            image {
              altText
              sourceUrl(size: LARGE)
            }
            link 
          }
        }
      }
    }
    posts(where: {language: $language}) {
      edges {
        node {
          id
          link
          title(format: RENDERED)
          blogPost {
            intro
            date
            image {
              altText
              sourceUrl(size: LARGE)
            }
            author {
              name
              image {
                altText
                sourceUrl(size: THUMBNAIL)
              }
            }
            blogContent {
              ... on Post_Blogpost_BlogContent_RichtextModule {
                content
                fieldGroupName
              }
              ... on Post_Blogpost_BlogContent_QuoteModule {
                fieldGroupName
                quote
                quoteBy
              }
              ... on Post_Blogpost_BlogContent_VideoModule {
                fieldGroupName
                video {
                  poster {
                    sourceUrl(size: LARGE)
                  }
                  id
                }
              }
            }
          }
          slug
          language {
            locale
          }
          terms {
            ... on Category {
              name
            }
          }
        }
      }
    }
  }
`;

const GET_BLOG_POST = gql`
  query getBlogPost (
    $id: ID!
  ) {
    post(id: $id, idType: SLUG) {
      terms {
        ... on Category {
          name
        }
      }
      title
      blogPost {
        date
        blogContent {
          ... on Post_Blogpost_BlogContent_RichtextModule {
            content
            fieldGroupName
          }
          ... on Post_Blogpost_BlogContent_QuoteModule {
            fieldGroupName
            quote
            quoteBy
          }
          ... on Post_Blogpost_BlogContent_VideoModule {
            fieldGroupName
            video {
              id
              poster {
                sourceUrl(size: MEDIUM)
              }
            }
          }
        }
        author {
          name
          image {
            sourceUrl(size: THUMBNAIL)
          }
          fieldGroupName
        }
        date
        image {
          sourceUrl(size: LARGE)
        }
        relatedPosts {
          ... on Post {
            title
            slug
            link
            blogPost {
              date
              intro
              image {
                sourceUrl(size: LARGE)
              }
              author {
                name
                fieldGroupName
              }
            }
            terms {
              ... on Category {
                name
              }
            }
          }
        }
      }
      
    }
  }
`;

export {
  GET_ALL_ROUTES_AND_MENUS,
  GET_FRONTPAGE_CONTENT,
  GET_PAGE_CONTENT,
  GET_BLOG_POSTS,
  GET_BLOG_POST
};
