export default {
  Mutation: {},
};

const defaults = {
  isMembersSection: false,
  popupDismissed: sessionStorage.getItem('popupDismissed'),
  currentLanguage: 'DA',
};

const resolvers = {}

export { defaults, resolvers };
